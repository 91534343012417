.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #b8b8b8;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.33333;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #b8b8b8;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1374px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.main-content {
  overflow-x: clip;
}

.tx-wvier-clientcockpit {
  margin-top: -4rem;
}

@media (min-width: 992px) {
  .tx-wvier-clientcockpit {
    margin-top: 0;
  }
}

.tx-wvier-clientcockpit .login-splash-wrapper {
  position: relative;
}

.tx-wvier-clientcockpit .login-splash-wrapper .splash-icon {
  position: absolute;
}

.tx-wvier-clientcockpit .login-splash-wrapper .splash-icon.top {
  width: 445px;
  height: 450px;
  right: -400px;
  top: -130px;
  z-index: -1;
}

.tx-wvier-clientcockpit .login-splash-wrapper .splash-icon.bottom {
  width: 505px;
  height: 510px;
  left: -380px;
  top: 370px;
  z-index: -1;
}

.tx-wvier-clientcockpit .login-splash-wrapper .splash-icon svg {
  fill: #fff;
  width: 100%;
  height: 100%;
}

.tx-wvier-clientcockpit .login-wrapper,
.tx-wvier-clientcockpit .sidebar-wrapper {
  background-color: #fff;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.16));
  padding: 40px;
  position: relative;
  top: 0;
  border-radius: 5px;
}

.tx-wvier-clientcockpit .login-wrapper .points-wrapper,
.tx-wvier-clientcockpit .sidebar-wrapper .points-wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
}

.tx-wvier-clientcockpit .login-wrapper .points-wrapper .points-btn,
.tx-wvier-clientcockpit .sidebar-wrapper .points-wrapper .points-btn {
  display: flex;
  align-items: center;
  background-color: #00207a;
  color: #fff;
  padding: 6px 10px;
  border-radius: 20px;
}

.tx-wvier-clientcockpit .login-wrapper .points-wrapper .points-btn .icon,
.tx-wvier-clientcockpit .sidebar-wrapper .points-wrapper .points-btn .icon {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.tx-wvier-clientcockpit .login-wrapper .points-wrapper .points-btn .icon svg,
.tx-wvier-clientcockpit .sidebar-wrapper .points-wrapper .points-btn .icon svg {
  width: 100%;
  height: 100%;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .profile-image,
.tx-wvier-clientcockpit .sidebar-wrapper .profile-wrapper .profile-image {
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .profile-image svg,
.tx-wvier-clientcockpit .sidebar-wrapper .profile-wrapper .profile-image svg {
  width: 75px;
  height: 75px;
  fill: #00207a;
  background-color: #f6f6f6;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .profile-image img,
.tx-wvier-clientcockpit .sidebar-wrapper .profile-wrapper .profile-image img {
  width: 100%;
  object-fit: cover;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .username,
.tx-wvier-clientcockpit .sidebar-wrapper .profile-wrapper .username {
  font-weight: 500;
  display: block;
  text-align: center;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out {
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out,
  .tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out {
    flex-direction: row;
    gap: 15px;
  }
}

@media (min-width: 992px) {
  .tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out,
  .tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out {
    flex-direction: column;
    gap: 0;
  }
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-wrapper,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-wrapper,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-wrapper,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-wrapper {
  width: 100%;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-header,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-header,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-header,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-header {
  font-size: 1.125rem;
  color: #00207a;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 25px;
  background-color: #f6f6f6;
  padding: 12px 15px;
  border-radius: 3px;
  cursor: pointer;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-header .header-icon,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-header .header-icon,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-header .header-icon,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-header .header-icon {
  display: block;
  width: 25px;
  height: 25px;
  fill: #00207a;
  margin-right: 15px;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-header .chevron-icon,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-header .chevron-icon,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-header .chevron-icon,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-header .chevron-icon {
  margin-left: auto;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-header .arrow,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-header .arrow,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-header .arrow,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-header .arrow {
  display: block;
  width: 9px;
  height: 18px;
  fill: #00207a;
  margin-left: 25px;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-header.hide .chevron-icon,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-header.hide .chevron-icon,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-header.hide .chevron-icon,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-header.hide .chevron-icon {
  transform: rotate(180deg);
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-body,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-body,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-body,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-body {
  display: none;
  flex-direction: column;
}

@media (min-width: 992px) {
  .tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-body,
  .tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-body,
  .tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-body,
  .tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-body {
    display: flex;
  }
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-body.hide,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-body.hide,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-body.hide,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-body.hide {
  display: flex;
}

@media (min-width: 992px) {
  .tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-body.hide,
  .tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-body.hide,
  .tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-body.hide,
  .tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-body.hide {
    display: none;
  }
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-body a,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-body a,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-body a,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-body a {
  padding: 12px 15px;
  border-bottom: 1px solid #f6f6f6;
  text-decoration: none;
}

.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .club-body a:hover,
.tx-wvier-clientcockpit .login-wrapper .accordion-wrapper-out .account-body a:hover,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .club-body a:hover,
.tx-wvier-clientcockpit .sidebar-wrapper .accordion-wrapper-out .account-body a:hover {
  color: #00207a;
}

@media (min-width: 992px) {
  .tx-wvier-clientcockpit .sidebar-wrapper-out {
    position: sticky;
    top: 130px;
    max-width: 400px;
  }
}

.tx-wvier-clientcockpit .login-wrapper {
  margin-bottom: 50px;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper {
  margin-bottom: 50px;
  margin-top: 30px;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .profile-image {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #00207a;
  padding: 12px;
  border-radius: 100px;
  width: 68px;
  height: 68px;
  transform: translateX(-50%) translateY(-50%);
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .profile-image svg {
  fill: #fff;
  background-color: #00207a;
  width: 44px;
  height: 44px;
}

.tx-wvier-clientcockpit .login-wrapper .profile-wrapper .username {
  font-weight: 500;
}

.tx-wvier-clientcockpit .login-wrapper form {
  margin-bottom: 0;
}

.tx-wvier-clientcockpit .login-wrapper form .form-set {
  margin-bottom: 0;
}

.tx-wvier-clientcockpit .login-wrapper form .form-set .actions .form-group {
  margin-bottom: 10px;
}

.tx-wvier-clientcockpit .login-wrapper form .lostpwd {
  font-size: 14px;
}

.tx-wvier-clientcockpit .login-wrapper .verification {
  text-align: center;
}

.tx-wvier-clientcockpit .login-wrapper .verification .profile-wrapper {
  margin-bottom: 20px;
}

.tx-wvier-clientcockpit .login-wrapper .verification .first-line {
  font-weight: bold;
}

.tx-wvier-clientcockpit .login-wrapper .verification .last-line {
  color: #404040;
  margin-top: 40px;
}

.tx-wvier-clientcockpit .register-link {
  color: #00207a;
  font-weight: 500;
}

.loader {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.loader-part1,
.loader-part2,
.loader-part3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #00207a;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

.loader-part1 {
  animation-delay: -0.2s;
}

.loader-part2 {
  animation-delay: -0.4s;
  border-color: #fff;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

body.applogin nav,
body.applogin footer {
  display: none;
}

body.applogin main .frame-type-wvier_heroimage,
body.applogin main .frame-type-wvier_banner,
body.applogin main .info-register-link {
  display: none;
}

.intro-wrapper {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.16);
  margin-bottom: 60px;
}

.intro-wrapper .intro-img-wrapper {
  display: flex;
  background-image: url("/typo3conf/ext/wvier_clientcockpit/Resources/Public/Images/clientcockpit-intro.png");
  color: #fff;
  width: 100%;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  background-position: center;
  padding: 30px;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  flex-direction: column;
}

@media (min-width: 576px) {
  .intro-wrapper .intro-img-wrapper {
    flex-direction: row;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .intro-wrapper .intro-img-wrapper {
    height: 175px;
  }
}

.intro-wrapper .intro-img-wrapper::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00207a;
  opacity: 0.3;
  z-index: 0;
}

.intro-wrapper .intro-img-wrapper .intro-name,
.intro-wrapper .intro-img-wrapper .intro-points-wrapper {
  z-index: 1;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: bold;
}

.intro-wrapper .intro-img-wrapper .name {
  margin: 0;
}

.intro-wrapper .intro-img-wrapper .points-wrapper {
  display: flex;
  gap: 3px;
  align-items: flex-end;
}

.intro-wrapper .intro-img-wrapper .points-wrapper .points {
  font-size: 70px;
  line-height: 1;
}

.intro-wrapper .intro-img-wrapper .points-wrapper .text {
  font-size: 26px;
}

.intro-wrapper .intro-bottom-wrapper {
  background-color: #fff;
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
}

.intro-wrapper .intro-bottom-wrapper .intro-bottom-section {
  width: 100%;
  padding: 7px 30px;
}

.intro-wrapper .intro-bottom-wrapper .intro-bottom-section.scan-invoice-wrapper {
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
}

.intro-wrapper .intro-bottom-wrapper .intro-bottom-section .cta-item {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.intro-wrapper .intro-bottom-wrapper .intro-bottom-section .cta-item:hover {
  text-decoration: underline;
}

.intro-wrapper .intro-bottom-wrapper .intro-bottom-section .cta-item svg {
  width: 50px;
  height: 50px;
}

.intro-wrapper .intro-bottom-wrapper .intro-bottom-section .cta-item .title {
  font-weight: bold;
  display: block;
  text-align: center;
}

.table-wrapper {
  margin-top: 25px;
  max-width: 570px;
  width: 100%;
}

.table-wrapper .table-row {
  padding: 15px 0;
  display: flex;
  border-bottom: 1px #ccc dotted;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.howitworks-wrapper {
  margin-bottom: 70px;
}

.howitworks-wrapper .table-wrapper .table-row {
  justify-content: flex-start;
}

.howitworks-wrapper .table-wrapper .table-row .points {
  font-weight: bold;
  width: 140px;
}

.howitworks-wrapper .stars-wrapper {
  font-size: 14px;
  margin-top: 20px;
}

.slidemanager-wrapper .alert {
  padding: 20px;
  background-color: #fff;
  color: #00207a;
  font-weight: bold;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}

.slidemanager-wrapper .slidemanager-links-wrapper .slidemanager-link {
  background-color: #00207a;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 3px;
  border: #00207a solid 3px;
}

.slidemanager-wrapper .slidemanager-links-wrapper .slidemanager-link .link-text {
  text-align: left;
  font-weight: bold;
}

.slidemanager-wrapper .slidemanager-links-wrapper .slidemanager-link .link-text .big {
  font-size: 30px;
}

.slidemanager-wrapper .slidemanager-links-wrapper .slidemanager-link .link-icon svg {
  width: 26px;
  height: 26px;
}

.slidemanager-wrapper .slidemanager-links-wrapper .slidemanager-link:hover {
  background-color: transparent;
  color: #00207a;
}

.slidemanager-wrapper .sm-config-btn {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  padding: 25px 35px 20px;
}

.slidemanager-wrapper .sm-config-btn .inner {
  text-decoration: none;
}

.slidemanager-wrapper .sm-config-btn .inner .big {
  text-decoration: none;
  margin-bottom: 15px;
}

.slidemanager-wrapper .sm-config-btn .config-text-container {
  border-bottom: dashed 1px #b8b8b8;
  padding-bottom: 25px;
}

.slidemanager-wrapper .sm-config-btn .config-text-container .data-container {
  color: #00207a;
  font-weight: bold;
  font-size: 20px;
}

.slidemanager-wrapper .sm-config-btn .config-text-container .data-container .config-label {
  margin-right: 5px;
}

.slidemanager-wrapper .sm-config-btn .config-text-container .config-nodata {
  color: #b8b8b8;
  font-style: italic;
}

.slidemanager-wrapper .sm-config-btn .btn {
  color: #00207a;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.slidemanager-wrapper .sm-config-btn .btn.sm-config-nn-btn {
  margin-top: 24px;
}

.slidemanager-wrapper .sm-config-btn .btn.sm-delete-nn-btn {
  margin-top: 10px;
}

.cta-profile-wrapper .cta-app .image {
  height: 350px;
}

@media (min-width: 1374px) {
  .cta-profile-wrapper .cta-app .image {
    height: auto;
  }
}

.cta-profile-wrapper .cta-app .hero-caption {
  font-size: 40px;
}

.cta-app-sidebar-wrapper .cta-app .image {
  height: 350px;
}

@media (min-width: 1374px) {
  .cta-app-sidebar-wrapper .cta-app .image {
    height: 250px;
  }
}

.cta-app-sidebar-wrapper .cta-app .hero-caption {
  font-size: 30px;
  text-align: center;
}

.cta-app-sidebar-wrapper .cta-app .cta-app-wrapper .cta-app-inner .app-links-wrapper {
  gap: 5px;
}

.cta-app-sidebar-wrapper .cta-app .cta-app-wrapper .cta-app-inner .app-links-wrapper svg {
  height: 40px;
}

.cta-app-sidebar-wrapper .cta-app .cta-app-inner {
  padding: 40px 15px;
}

.allbounties-wrapper {
  max-width: 570px;
  width: 100%;
  margin-bottom: 70px;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 20px;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .img-wrapper {
  flex-shrink: 0;
  background-color: #ccc;
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .text-wrapper {
  width: 100%;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .text-wrapper .bounty-title {
  font-weight: bold;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .text-wrapper .bounty-points {
  font-size: 14px;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .text-wrapper progress {
  width: 100%;
  height: 7px;
  border-radius: 50px;
  background-color: white;
  transition: width 300ms ease;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .text-wrapper progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 7px;
  border-radius: 50px;
  background-color: white;
  transition: width 300ms ease;
}

.allbounties-wrapper .list-bounties-wrapper .bounty-wrapper .text-wrapper progress[value]::-webkit-progress-value {
  width: 0;
  border-radius: 50px;
  background-color: #00207a;
  transition: width 300ms ease;
}

.modal .modal-content {
  border-radius: 5px;
  border: none;
  overflow: hidden;
}

.modal .modal-header {
  padding: 1rem 1.5rem;
  background-color: #00207a;
  color: white;
}

.modal .modal-body {
  padding: 2rem 1.5rem;
}

.modal .btn {
  border: 1px solid;
  padding: 0.55rem 0.5rem;
  border-radius: 5px;
}

.modal .btn::after {
  content: none;
}

.modal .btn.btn-dismiss {
  color: #fff;
  background-color: #808080;
  border-color: #808080;
}

.modal .btn.btn-dismiss:hover, .modal .btn.btn-dismiss:focus, .modal .btn.btn-dismiss:active {
  color: #fff;
  background-color: #000;
}

.modal .btn.btn-confirm {
  color: #fff;
  background-color: #00207a;
  border-color: #00207a;
}

.modal .btn.btn-confirm:hover, .modal .btn.btn-confirm:focus, .modal .btn.btn-confirm:active {
  color: #fff;
  background-color: #000;
}
