@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/modal";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";

.main-content {
  overflow-x: clip;
}

.tx-wvier-clientcockpit {
  margin-top: -4rem;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .login-splash-wrapper {
    position: relative;

    .splash-icon {
      position: absolute;

      &.top {
        width: 445px;
        height: 450px;
        right: -400px;
        top: -130px;
        z-index: -1;
      }

      &.bottom {
        width: 505px;
        height: 510px;
        left: -380px;
        top: 370px;
        z-index: -1;
      }

      svg {
        fill: $white;
        width: 100%;
        height: 100%;
      }
    }
  }

  .login-wrapper,
  .sidebar-wrapper {
    background-color: $white;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.16));
    padding: 40px;
    position: relative;
    top: 0;
    border-radius: 5px;

    .points-wrapper {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 10px;
      right: 10px;

      .points-btn {
        display: flex;
        align-items: center;
        background-color: $blue;
        color: $white;
        padding: 6px 10px;
        border-radius: 20px;

        .icon {
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .profile-wrapper {
      .profile-image {
        display: block;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 15px;

        svg {
          width: 75px;
          height: 75px;
          fill: $primary;
          background-color: $gray-100;
        }

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .username {
        font-weight: $font-weight-medium;

        display: block;
        text-align: center;
      }
    }

    .accordion-wrapper-out {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 15px;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        gap: 0;
      }

      .club,
      .account {
        &-wrapper {
          width: 100%;
        }

        &-header {
          font-size: $font-size-base;
          color: $blue;
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-top: 25px;
          background-color: $gray-100;
          padding: 12px 15px;
          border-radius: 3px;
          cursor: pointer;

          .header-icon {
            display: block;
            width: 25px;
            height: 25px;
            fill: $blue;
            margin-right: 15px;
          }

          .chevron-icon {
            margin-left: auto;
          }

          .arrow {
            display: block;
            width: 9px;
            height: 18px;
            fill: $blue;
            margin-left: 25px;
          }

          &.hide {
            .chevron-icon {
              transform: rotate(180deg);
            }
          }
        }

        &-body {
          display: none;
          flex-direction: column;

          @include media-breakpoint-up(lg) {
            display: flex;
          }

          &.hide {
            display: flex;

            @include media-breakpoint-up(lg) {
              display: none;
            }
          }

          a {
            padding: 12px 15px;
            border-bottom: 1px solid $gray-100;
            text-decoration: none;

            &:hover {
              color: $blue;
            }
          }
        }
      }
    }


  }

  @include media-breakpoint-up(lg) {
    .sidebar-wrapper-out {
      position: sticky;
      top: 130px;
      max-width: 400px;
    }
  }


  .login-wrapper {
    margin-bottom: 50px;

    .profile-wrapper {
      margin-bottom: 50px;
      margin-top: 30px;

      .profile-image {
        position: absolute;
        top: 0;
        left: 50%;
        background-color: $blue;
        padding: 12px;
        border-radius: 100px;
        width: 68px;
        height: 68px;
        transform: translateX(-50%) translateY(-50%);

        svg {
          fill: $white;
          background-color: $blue;
          width: 44px;
          height: 44px;
        }
      }

      .username {
        font-weight: $font-weight-medium;
      }
    }

    form {
      margin-bottom: 0;

      .form-set {
        margin-bottom: 0;

        .actions {
          .form-group {
            margin-bottom: 10px;
          }
        }
      }

      .lostpwd {
        font-size: 14px;
      }
    }

    .verification {
      text-align: center;

      .profile-wrapper {
        margin-bottom: 20px;
      }

      .first-line {
        font-weight: bold;
      }

      .last-line {
        color: $gray-500;
        margin-top: 40px;
      }
    }
  }

  .register-link {
    color: $blue;
    font-weight: $font-weight-medium;
  }
}


.loader {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.loader-part1,
.loader-part2,
.loader-part3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid $blue;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

.loader-part1 {
  animation-delay: -0.2s;
}

.loader-part2 {
  animation-delay: -0.4s;
  border-color: $white;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


body.applogin {
  nav,
  footer {
    display: none;
  }

  main {
    .frame-type-wvier_heroimage,
    .frame-type-wvier_banner,
    .info-register-link {
      display: none;
    }
  }
}


.intro-wrapper {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0,0,0,0.16);
  margin-bottom: 60px;

  .intro-img-wrapper {
    display: flex;
    background-image: url('/typo3conf/ext/wvier_clientcockpit/Resources/Public/Images/clientcockpit-intro.png');
    color: $white;
    width: 100%;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    background-position: center;
    padding: 30px;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: flex-end;
    }

    @include media-breakpoint-up(sm) {
      height: 175px;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $blue;
      opacity: 0.3;
      z-index: 0;
    }

    .intro-name,
    .intro-points-wrapper {
      z-index: 1;
      text-shadow: 0 3px 6px rgba(0,0,0,0.16);
      font-weight: bold;
    }

    .name {
      margin: 0;
    }

    .points-wrapper {
      display: flex;
      gap: 3px;
      align-items: flex-end;

      .points {
        font-size: 70px;
        line-height: 1;
      }

      .text {
        font-size: 26px;
      }
    }
  }

  .intro-bottom-wrapper {
    background-color: $white;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;

    .intro-bottom-section {
      width: 100%;
      padding: 7px 30px;

      &.scan-invoice-wrapper {
        border-left: solid 1px $gray-200;
        border-right: solid 1px $gray-200;
      }

      .cta-item {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &:hover {
          text-decoration: underline;
        }

        svg {
          width: 50px;
          height: 50px;
        }

        .title {
          font-weight: bold;
          display: block;
          text-align: center;
        }
      }


    }
  }
}

.table-wrapper {
  margin-top: 25px;
  max-width: 570px;
  width: 100%;

  .table-row {
    padding: 15px 0;
    display: flex;
    border-bottom: 1px $gray-200 dotted;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
}

.howitworks-wrapper {
  margin-bottom: 70px;

  .table-wrapper {
    .table-row {
      justify-content: flex-start;
      .points {
        font-weight: bold;
        width: 140px;
      }
    }
  }

  .stars-wrapper {
    font-size: 14px;
    margin-top: 20px;
  }
}

.slidemanager-wrapper {
  .alert {
    padding: 20px;
    background-color: $white;
    color: $blue;
    font-weight: bold;
    box-shadow: 0 0 30px rgba(0,0,0,0.07);
    border-radius: 5px;
  }

  .slidemanager-links-wrapper {
    .slidemanager-link {
      background-color: $blue;
      color: $white;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 3px;
      border: $blue solid 3px;

      .link-text {
        text-align: left;
        font-weight: bold;

        .big {
          font-size: 30px;
        }
      }

      .link-icon svg {
        width: 26px;
        height: 26px;
      }

      &:hover {
        background-color: transparent;

        color: $blue;
      }
    }
  }

  .sm-config-btn {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(0,0,0,0.07);
    padding: 25px 35px 20px;

    .inner {
      text-decoration: none;

      .big {
        text-decoration: none;
        margin-bottom: 15px;
      }
    }

    .config-text-container {
      border-bottom: dashed 1px $gray-300;
      padding-bottom: 25px;

      .data-container {
        color: $blue;
        font-weight: bold;
        font-size: 20px;

        .config-label {
          margin-right: 5px;
        }
      }

      .config-nodata {
        color: $gray-300;
        font-style: italic;
      }
    }

    .btn {
      color: $blue;
      padding: 0;
      display: flex;
      align-items: center;
      font-weight: bold;

      &.sm-config-nn-btn {
        margin-top: 24px;
      }

      &.sm-delete-nn-btn {
        margin-top: 10px;
      }
    }
  }
}

.cta-profile-wrapper {
  .cta-app {
    .image {
      height: 350px;

      @include media-breakpoint-up(xl) {
        height: auto;
      }
    }

    .hero-caption {
      font-size: 40px;
    }
  }
}

.cta-app-sidebar-wrapper {
  .cta-app {
    .image {
      height: 350px;

      @include media-breakpoint-up(xl) {
        height: 250px;
      }
    }

    .hero-caption {
      font-size: 30px;
      text-align: center;
    }

    .cta-app-wrapper .cta-app-inner .app-links-wrapper {
      gap: 5px;

      svg {
        height: 40px;
      }
    }

    .cta-app-inner {
      padding: 40px 15px;
    }
  }
}


.allbounties-wrapper {
  max-width: 570px;
  width: 100%;
  margin-bottom: 70px;

  .list-bounties-wrapper {
    .bounty-wrapper {
      display: flex;
      align-items: center;
      gap: 25px;
      margin-bottom: 20px;

      .img-wrapper {
        flex-shrink: 0;
        background-color: $gray-200;
        width: 80px;
        height: 80px;
        border-radius: 5px;
      }

      .text-wrapper {
        width: 100%;

        .bounty-title {
          font-weight: bold;
        }

        .bounty-points {
          font-size: 14px;
        }

        progress {
          width: 100%;
          height: 7px;
          border-radius: 50px;
          background-color: white;
          transition: width 300ms ease;
        }

        progress[value]::-webkit-progress-bar {
          width: 100%;
          height: 7px;
          border-radius: 50px;
          background-color: white;
          transition: width 300ms ease;
        }

        progress[value]::-webkit-progress-value {
          width: 0;
          border-radius: 50px;
          background-color: $primary;
          transition: width 300ms ease;
        }
      }
    }
  }
}

.modal {
  .modal-content {
    border-radius: 5px;
    border: none;
    overflow: hidden;
  }

  .modal-header {
    padding: 1rem 1.5rem;
    background-color: $primary;
    color: white;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }

  .btn {
    border: 1px solid;
    padding: 0.55rem 0.5rem;
    border-radius: 5px;

    &::after {
      content: none;
    }

    &.btn-dismiss {
      color: $white;
      background-color: $gray-400;
      border-color: $gray-400;

      @include hover-focus-active {
        color: $white;
        background-color: $black;
      }
    }

    &.btn-confirm {
      color: $white;
      background-color: $primary;
      border-color: $primary;

      @include hover-focus-active {
        color: $white;
        background-color: $black;
      }
    }
  }
}

















